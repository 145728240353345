import React from 'react';
import {
    TextInput,
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';

class SellingPointTypeCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {

        if (fieldName === "tag") {
            fieldList.push(<TextInput label={"Jingsocial tag ID for analytics"} source={fieldName} key={"Jingsocial tag ID for analytics"}/>);
            return true;
        }
        return false;
    }
}

export default SellingPointTypeCreate;
