import React from 'react';
import {
    TextField,
} from 'react-admin';
import CustomTableShow from './CustomTableShow';


class SellingPointTypeShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === "tag") {
            fieldList.push(<TextField label={"Jingsocial tag ID for analytics"} source={fieldName} />);
            return true;
        }

        return false;
    }
}

export default SellingPointTypeShow;