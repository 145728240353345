import React from 'react';
import {
    TextInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';
import MyInputFile from '../fields/MyInputFile';
import utils from "../utils/utils";

class SellingPointEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList) {

        if (fieldName === "code") {
            return true;
        } else if (fieldName === 'logo') {
            fieldList.push(<MyInputFile source={fieldName} label="Logo" folder="selling-point-logo" />);
            return true;
        } else if (fieldName === 'descriptionEN' || fieldName === 'descriptionCN' || fieldName === 'openingEN' || fieldName === 'openingCN') {
            let fieldLabel = fieldName.replace(/([A-Z])/g, ' $1').trim();
            fieldLabel = fieldLabel.charAt(0).toUpperCase() + fieldLabel.slice(1);
            fieldList.push(<TextInput label={fieldLabel} source={fieldName} fullWidth multiline />);
            return true;
        } else if (fieldName === "category") {
            fieldList.push(<SelectInput source={fieldName} label={"Category"} choices={this.transformInChoices(fields[fieldName].enum)} />);
            return true;
        } else if (fieldName === "type") {
            const plural = utils.getPlural(fields[fieldName].ref);
            fieldList.push(<ReferenceInput label={"Type"} source={"type"} reference={"selling-point-types"} allowEmpty>
                <AutocompleteInput optionText={utils.displayFieldForPlural(plural)} />
            </ReferenceInput>);
            return true;
        }
        return false;
    }
}

export default SellingPointEdit;
