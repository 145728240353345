export default {

    getPlural: (sigularWord) => {
        const lastChar = sigularWord.substr(sigularWord.length - 1);

        if (lastChar === 'y') {
            return `${sigularWord.substring(0, sigularWord.length - 1)}ies`;
        }
        return `${sigularWord}s`;
    },

    displayFieldForPlural: (plural) => {
        let optionText = 'name';

        if (plural === 'videos' || plural === 'series') {
            optionText = 'title';
        } else if (plural === 'surveys') {
            optionText = 'titleEN';
        } else if (plural === 'events' || plural === 'wines' || plural === 'selling-points' || plural === 'badges'
        || plural === 'celebrities' || plural === 'occasions' || plural === 'juries' || plural === 'selling-point-types') {
            optionText = 'nameEN';
        } else if (plural === 'wechat-users') {
            optionText = 'openid';
        } else if (plural === 'distributors' || plural === 'producers') {
            optionText = 'companyNameEN';
        } else if (plural === 'activities') {
            optionText = 'themeEN';
        }
        return optionText;
    }
};
