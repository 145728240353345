import React from 'react';
import {
    Filter,
    SelectInput,
    Show,
    SimpleShowLayout,
    ArrayField,
    Datagrid,
    ReferenceField,
    TextField,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import CustomTableList from './CustomTableList';

class SellingPointList extends CustomTableList {
    myFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn />

            <SelectInput label="Category" source="category" allowEmpty
                choices={[
                    { id: 'shop', name: 'Shop' },
                    { id: 'restaurant', name: 'Restaurant' },
                    { id: 'hotel', name: 'Hotel' },
                    { id: 'wine_store', name: 'Wine store' },
                    { id: 'online_channel', name: 'Online channel' },
                ]} />

            <ReferenceInput label="Type" source="type" reference="selling-point-types" alwaysOn allowEmpty perPage={500}>
                <AutocompleteInput optionText="nameEN" />
            </ReferenceInput>
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'descriptionEN' || fieldName === 'descriptionCN' || fieldName === 'openingEN' || fieldName === 'openingCN'
        || fieldName === 'wines' || fieldName === 'mpAppId' || fieldName === 'mpPagePath' || fieldName === 'code') {
            return true;
        } else if (fieldName === "type") {
            fieldList.push(<ReferenceField label={"Type"} source={"type"} reference={"selling-point-types"} allowEmpty>
                <TextField source={"nameEN"} />
            </ReferenceField>);
            return true;
        } else if (fieldName === "tag") {
            fieldList.push(<TextField label={"Jingsocial tag ID for analytics"} source={fieldName} />);
            return true;
        }

        return false;
    }

    PostShow(props) {

        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>
                <TextField label="MP Appid" source={'mpAppId'} />
                <TextField label="MP Page path" source={'mpPagePath'} />

                <TextField label="Description (EN)" source={'descriptionEN'} />
                <TextField label="Description (CN)" source={'descriptionCN'} />

                <TextField label="Opening hours (EN)" source={'openingEN'} />
                <TextField label="Opening hours (CN)" source={'openingCN'} />

                <ArrayField label={'Wines'} source={'wines'}>
                    <Datagrid>
                        <ReferenceField label={'Wine'} source={'wine'} reference={'wines'} allowEmpty>
                            <TextField source={'nameEN'} />
                        </ReferenceField>
                        <TextField label={'MP specific Page path'} source={'mpPagePath'} />
                    </Datagrid>
                </ArrayField>
            </SimpleShowLayout>
        </Show>;
    }
}

export default SellingPointList;
